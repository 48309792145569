<template>
	<div class="flex-1 flex space-x-8 items-center">
		<div class="flex-none w-32 rounded-full relative">
			
			<svg viewBox="0 0 36 36">
				<path 
					d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
					fill=" bg-gray-darkest" 
					class="text-gray-darker"
					stroke="currentColor" 
					stroke-width="2.98" 
					stroke-linecap="round"
				/>
				<path 
					v-if="campaign.type == 'products'"
					d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
					fill="none" 
					:class="campaignTotal.stats.sum >= campaignTotal.target.sum ? 'text-green' : 'text-yellow-500'"
					stroke="currentColor" 
					stroke-width="3" 
					:stroke-dasharray="`${campaignTotal.stats.sum >= campaignTotal.target.sum ? 100 : Math.round( campaignTotal.stats.sum / campaignTotal.target.sum * 100 )}, 100`" 
					stroke-linecap="round"
				/>
				<path 
					v-else-if="campaign.type == 'tasks'"
					d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
					fill="none" 
					:class="campaignTotal.stats.qty >= campaignTotal.target.qty ? 'text-green' : 'text-yellow-500'"
					stroke="currentColor" 
					stroke-width="3" 
					:stroke-dasharray="`${campaignTotal.stats.qty >= campaignTotal.target.qty ? 100 : Math.round( campaignTotal.stats.qty / campaignTotal.target.qty * 100 )}, 100`" 
					stroke-linecap="round"
				/>
			</svg>
			
			<div class="absolute inset-0 flex items-center justify-center text-2xl">
				<div class="font-bold items-start flex text-white">
					<span v-if="campaign.type == 'products'">
						{{ `${campaignTotal.stats.sum >= campaignTotal.target.sum ? 100 : Math.round( campaignTotal.stats.sum / campaignTotal.target.sum * 100 )}` }}%
					</span>
					<span v-else-if="campaign.type == 'tasks'">
						{{ `${campaignTotal.stats.qty >= campaignTotal.target.qty ? 100 : Math.round( campaignTotal.stats.qty / campaignTotal.target.qty * 100 )}` }}%
					</span>
				</div>
			</div>
		</div>
		
		<div class="leading-none">
			<h1 class="my-2 text-2xl">{{ campaign.name }}</h1>
			<h3 class="m-0">
				<span v-if="campaign.endAt > new Date().getTime()">
					Kampanjen avsluttes {{ campaign.endAt | moment('from') }}
				</span>
				<span v-else-if="campaign.startAt > new Date().getTime()">
					Kampanjen starter {{ campaign.endAt | moment('from') }}
				</span>
				<span v-else-if="campaign.endAt < new Date().getTime()">
					Kampanjen var avsluttet {{ campaign.endAt | moment('from') }}
				</span>
			</h3>
		</div>
		
	</div>
</template>

<script>
	export default {
		
        props: {
			campaign: {
				type: Object,
				required: true,
			},
		},
		computed:{
			campaignTotal(){
				if ( this.campaign.campaignTotal ) {
					return this.campaign.campaignTotal;
				}
				return null;
			}
		},
		
		data(){
			return {
				loading: null,
			}
		},
		
		methods: {
			// 
		},
	}
</script>